<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-form ref="form">
      <v-layout wrap justify-center id="cf">
        <v-flex xs12 ma-6>
          <v-layout wrap>
            <v-flex xs12>
              <!-- src="../../assets/images/food6.jpg" -->

              <v-card
                class="mx-auto elevation-1 pa-6"
                max-width="1088px"
                align="center"
                outlined
              >
                <v-layout wrap>
                  <v-flex
                    mr-lg-10
                    xs12
                    sm3
                    lg6
                    class="text-left nsbold"
                    style="font-weight: 700; font-size: 20px"
                    >Menu
                  </v-flex>
                  <v-flex xs12 sm3 lg2 pr-sm-2 pa-2 pa-sm-0 class="nsregular">
                    <v-select
                      :items="Type"
                      v-model="mytype"
                      label="Type"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 lg2 pa-2 pa-sm-0>
                    <v-select
                      :items="HostelList"
                      v-model="hostelName"
                      label="Hostel"
                      item-text="hostelName"
                      item-value="_id"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs4 sm3 lg1 pl-2>
                    <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                    <v-btn
                      x-small
                      width="100px"
                      dark
                      color="#766BC0"
                      class="py-4"
                      @click="adddialogue = true"
                    >
                      <v-icon x-small>mdi-plus</v-icon>
                      <span
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 12px"
                        >Add</span
                      ></v-btn
                    >
                    <!-- </v-card> -->
                  </v-flex>
                </v-layout>
                <!----------------------------------------menu-------------------------------------------->
                <v-layout wrap pt-0 pt-md-6>
                  <v-flex xs12 lg12>
                    <v-layout wrap justify-start>
                      <v-flex xs12>
                        <v-layout wrap class="hidden-sm-and-down">
                          <v-flex
                            xs2
                            align-self-center
                            text-center
                            class="nsbold"
                            pa-2
                          >
                            <v-spacer></v-spacer>
                          </v-flex>
                          <v-flex sm10>
                            <v-layout wrap justify-center>
                              <v-flex
                                xs2
                                md3
                                align-self-center
                                text-center
                                class="nsbold"
                                pa-2
                              >
                                <span>Breakfast</span>
                              </v-flex>
                              <v-flex
                                xs2
                                md3
                                align-self-center
                                text-center
                                class="nsbold"
                                pa-2
                              >
                                <span>Lunch</span>
                              </v-flex>
                              <v-flex
                                xs2
                                md3
                                align-self-center
                                text-center
                                class="nsbold"
                                pa-2
                              >
                                <span>Snacks</span>
                              </v-flex>
                              <v-flex
                                xs2
                                md3
                                align-self-center
                                text-center
                                class="nsbold"
                                pa-2
                              >
                                <span>Dinner</span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        xs12
                        lg12
                        align-self-center
                        v-for="(values, i) in menu1"
                        :key="i"
                        py-2
                      >
                        <v-card tile flat>
                          <v-layout wrap pa-0 pa-md-2>
                            <v-flex
                              xs12
                              sm2
                              align-self-center
                              text-left
                              class="nsbold"
                            >
                              <span>{{ values._id }}</span>
                            </v-flex>
                            <v-flex xs12 align-self-center sm10>
                              <v-layout wrap justify-start>
                                <v-flex
                                  xs12
                                  sm6
                                  md3
                                  px-2
                                  py-2 py-lg-0
                                  v-for="(value, j) in values.typelist"
                                  :key="j"
                                >
                                  <v-card v-if="Images.length > 0" tile>
                                    <v-layout wrap v-if="Images[j]">
                                      <v-flex xs12 v-if="Images[j].imgurl">
                                        <v-img
                                          :src="Images[j].imgurl"
                                          class="white--text align-center"
                                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)"
                                          height="120px"
                                        >
                                          <span class="nsbold pa-2">
                                            {{ value.name }}
                                          </span>
                                        </v-img>
                                      </v-flex>
                                    </v-layout>

                                    <v-card-actions>
                                      <v-layout wrap>
                                        <v-flex xs6 sm6>
                                          <template v-if="value._id">
                                            <v-dialog
                                              v-model="value.dialog"
                                              persistent
                                              max-width="400px"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  plain
                                                  title="Edit"
                                                  block
                                                  tile
                                                  x-small
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  
                                                  @click="currentItem = value"
                                                >
                                                  <span  style="font-family:nunitosansbold ;font-size:12px ;text-transform:none"
                                                    >Edit</span
                                                  >
                                                  <v-icon
                                                    small
                                                    @click="currentItem = value"
                                                    style="cursor: pointer"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >mdi-pen</v-icon
                                                  >
                                                </v-btn>
                                              </template>
                                              <v-card>
                                                <v-toolbar
                                                  dark
                                                  color="#766BC0"
                                                  dense
                                                  flat
                                                  class="body-2"
                                                >
                                                  <v-toolbar-title>
                                                    Edit Menu
                                                  </v-toolbar-title>
                                                </v-toolbar>

                                                <v-layout
                                                  pt-4
                                                  wrap
                                                  v-if="currentItem"
                                                >
                                                  <v-flex xs12 px-4 py-2>
                                                    <v-text-field
                                                      v-model="currentItem.type"
                                                      outlined
                                                      disabled
                                                      dense
                                                      label="Type"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 px-4 py-2>
                                                    <v-text-field
                                                      v-model="currentItem.name"
                                                      outlined
                                                      dense
                                                      :rules="[rules.required]"
                                                      label="Menu"
                                                    ></v-text-field>
                                                  </v-flex>
                                                </v-layout>
                                                <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    color="grey darken-1"
                                                    text
                                                    @click="
                                                      value.dialog = false
                                                    "
                                                  >
                                                    Close
                                                  </v-btn>
                                                  <v-btn
                                                    outlined
                                                    color="#766BC0 "
                                                    @click="
                                                      (value.dialog = false),
                                                        editCurrent()
                                                    "
                                                  >
                                                    Save
                                                  </v-btn>
                                                </v-card-actions>
                                              </v-card>
                                            </v-dialog>
                                          </template>
                                          <template v-else>
                                            <v-btn
                                              plain
                                              x-small
                                              block
                                              tile
                                              outlined
                                              @click="
                                                (adddialogue = true),
                                                  (day = values._id),
                                                  (hostel = hostelName),
                                                  (timing = Time[value.order]),
                                                  (ex7 = mytype)
                                              "
                                              >Edit
                                              <v-icon
                                                small
                                                @click="
                                                  (adddialogue = true),
                                                    (day = values._id),
                                                    (hostel = hostelName),
                                                    (timing =
                                                      Time[value.order]),
                                                    (ex7 = mytype)
                                                "
                                                >mdi-pen</v-icon
                                              >
                                            </v-btn>
                                          </template>
                                        </v-flex>
                                        <v-flex xs6 pl-1>
                                          <v-tooltip top>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                tile
                                                x-small
                                                block
                                                
                                                plain
                                                @click="
                                                  (dialoge = true),
                                                    (curId = value._id)
                                                "
                                              ><span style="font-family:nunitosansbold ;font-size:12px ;text-transform:none">
                                                Delete
                                              </span>
                                                
                                                <v-icon
                                                  small
                                                  style="cursor: pointer"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  @click="
                                                    (dialoge = true),
                                                      (curId = value._id)
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-btn>
                                            </template>
                                            <span>Delete</span>
                                          </v-tooltip>
                                        </v-flex>
                                      </v-layout>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="shopDetails">
                      <v-flex
                        xs12
                        v-if="shopDetails.billNo"
                        text-left
                        class="nsregular"
                        style="font-size: 18px"
                        pt-4
                        pb-2
                      >
                        <span>
                          Shop Name:{{ shopDetails.shopName.shopName }}
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        v-if="shopDetails.billNo"
                        text-left
                        class="nsregular"
                        style="font-size: 18px"
                        pb-2
                      >
                        <span>
                          Total Amount:{{ shopDetails.totalAmount }}
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        v-if="shopDetails.billNo"
                        text-left
                        class="nsregular"
                        style="font-size: 18px"
                        pb-2
                      >
                        <span> Discount:{{ shopDetails.discount }} </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 lg6 v-if="purchaseItem">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">no.</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Qty.</th>
                            <th class="text-left">Price</th>
                            <th class="text-left">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in purchaseItem" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ value.itemName.itemName }}</td>
                            <td>{{ value.quantity }}</td>

                            <td>{{ value.price }}</td>

                            <td>{{ value.quantity * value.price }}</td>

                            <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>

          <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm Delete </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Are you sure you want to delete this record?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="#766BC0"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="deleteCategory()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog width="400px" v-model="editdialogue">
            <v-card width="400px">
              <v-toolbar dark color="grey" dense flat class="body-2">
                <v-toolbar-title> Edit Item </v-toolbar-title>
              </v-toolbar>

              <v-layout wrap v-if="curItem">
                <v-flex xs12 px-4 py-2>
                  <v-text-field
                    v-model="curItem.itemName"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 pb-2>
                  <v-text-field
                    v-model="curItem.itemType"
                    outlined
                    dense
                    label="Item Type"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="editdialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="editCategory()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog width="400px" v-model="adddialogue">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Add Menu </v-toolbar-title>
              </v-toolbar>

              <v-layout wrap pt-4>
                <v-flex xs12 px-4 py-2>
                  <v-select
                    :items="HostelList"
                    v-model="hostel"
                    label="Hostel"
                    item-text="hostelName"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 px-4 pb-2>
                  <v-select
                    :items="Day"
                    v-model="day"
                    clearable
                    label="Day"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 px-4 pb-2>
                  <v-select
                    :items="Time"
                    v-model="timing"
                    clearable
                    label="Time"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 px-4 pb-2 align-self-center>
                  <v-radio-group v-model="ex7" column hide-details dense>
                    <v-layout wrap justify-center>
                      <v-flex xs12 lg6 :rules="[rules.required]">
                        <v-radio
                          label="Non-Veg"
                          color="red"
                          value="Non-Veg"
                        ></v-radio>
                      </v-flex>
                      <v-flex xs12 lg6>
                        <v-radio
                          label=" Veg"
                          color="success"
                          value="Veg"
                        ></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 px-4 pb-2 pt-4>
                  <!-- {{menus}}... -->
                  <v-text-field
                    v-model="menus"
                    clearable
                    label="Menu"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="adddialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="#766BC0"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="addCategory()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      Type: ["Veg", "Non-Veg"],
      mytype: "Non-Veg",
      menus: null,
      menu1: [],
      // menu1: null,

      ex7: " ",
      day: null,
      HostelList: [],
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      typeList: null,
      typee: "",
      hostelName: null,
      Time: ["Breakfast", "Lunch", "Snacks", "Dinner"],
      Day: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      Images: [
        { imgurl: require("../../assets/images/food1.jpg") },
        { imgurl: require("../../assets/images/food2.jpg") },
        { imgurl: require("../../assets/images/food7.jpg") },
        { imgurl: require("../../assets/images/food6.jpg") },
      ],
      quantitytype: null,
      productType: "",
      timing: null,
      hostel: null,
      weightType: ["Gram", "Kilo gram"],
      litersType: ["Milli Liter", "Liter"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      allData: [],
      purchaseItem: null,
      shopDetails: null,
      currentItem: null,
    };
  },
  mounted() {
    this.gethostel();
    this.getData();
  },
  watch: {
    hostelName() {
      this.getData();
    },
    mytype() {
      this.getData();
    },
  },
  methods: {
    editCurrent() {
      var menusAr = [],
        data = {};
      data["name"] = this.currentItem.name;
      data["itemId"] = this.currentItem._id;
      data["day"] = this.currentItem.day;
      data["timing"] = this.currentItem.timing;
      data["hostelName"] = this.hostelName;
      data["type"] = this.currentItem.type;
      menusAr.push(data);
      axios({
        url: "/menu/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          day: this.currentItem.day,
          hostelId: this.hostelName,
          menu: menusAr,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          this.name = null;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // grp(data) {
    //   var breakfast = [],
    //     lunch = [],
    //     dinner = [],
    //     snacks = [];
    //   var all = [];
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].timimg == "Breakfast") {
    //       //   fresh[0] = data[i];
    //       breakfast.push(data[i]);
    //     }
    //     if (data[i].timimg == "Lunch") {
    //       lunch.push(data[i]);
    //     }

    //     if (data[i].timimg == "Snacks") {
    //       snacks.push(data[i]);
    //     }

    //     if (data[i].timimg == "Dinner") {
    //       dinner.push(data[i]);
    //     }
    //   }

    //   all = all.concat(breakfast);
    //   all = all.concat(lunch);
    //   all = all.concat(snacks);
    //   all = all.concat(dinner);
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/menu/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          hostelName: this.hostelName,
          type: this.mytype,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.menu1 = response.data.data;
            if (this.menu1.length > 0) {
              for (let i = 0; i < this.menu1.length; i++) {
                if (this.menu1[i]._id == "Sunday") {
                  this.menu1[i].order = 0;
                }
                if (this.menu1[i]._id == "Monday") {
                  this.menu1[i].order = 1;
                }

                if (this.menu1[i]._id == "Tuesday") {
                  this.menu1[i].order = 2;
                }

                if (this.menu1[i]._id == "Wednesday") {
                  this.menu1[i].order = 3;
                }

                if (this.menu1[i]._id == "Thursday") {
                  this.menu1[i].order = 4;
                }
                if (this.menu1[i]._id == "Friday") {
                  this.menu1[i].order = 5;
                }
                if (this.menu1[i]._id == "Saturday") {
                  this.menu1[i].order = 6;
                }
                var subAr = [];

                for (let j = 0; j < this.menu1[i].typelist.length; j++) {
                  if (this.menu1[i].typelist[j].timing == "Breakfast") {
                    this.menu1[i].typelist[j].order = 0;
                  }
                  if (this.menu1[i].typelist[j].timing == "Lunch") {
                    this.menu1[i].typelist[j].order = 1;
                  }

                  if (this.menu1[i].typelist[j].timing == "Snacks") {
                    this.menu1[i].typelist[j].order = 2;
                  }

                  if (this.menu1[i].typelist[j].timing == "Dinner") {
                    this.menu1[i].typelist[j].order = 3;
                  }
                  subAr.push(this.menu1[i].typelist[j].timing);
                }
                if (!subAr.includes("Snacks")) {
                  var newValue1 = {};
                  newValue1.name = "NA";
                  newValue1.order = 2;
                  this.menu1[i].typelist.push(newValue1);
                }
                if (!subAr.includes("Dinner")) {
                  var newValue2 = {};
                  newValue2.name = "NA";
                  newValue2.order = 3;
                  this.menu1[i].typelist.push(newValue2);
                }

                if (!subAr.includes("Breakfast")) {
                  var newValue3 = {};
                  newValue3.name = "NA";
                  newValue3.order = 0;
                  this.menu1[i].typelist.push(newValue3);
                }

                if (!subAr.includes("Lunch")) {
                  var newValue4 = {};
                  newValue4.name = "NA";
                  newValue4.order = 1;
                  this.menu1[i].typelist.push(newValue4);
                }
                subAr = [];
                this.menu1[i].typelist.sort((a, b) => {
                  return a.order - b.order;
                });

              }
              this.menu1.sort((a, b) => {
                return a.order - b.order;
              });
            }
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/menu/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //call item type
    gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    //add button
    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/menu/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            day: this.day,
            timing: this.timing,
            hostelName: this.hostel,
            type: this.ex7,
            Name: this.menus,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            this.clearOff();
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.name = null;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },

    //edit button
    clearOff() {
      this.day = null;
      this.timing = null;
      this.hostel = null;
      this.ex7 = null;
      this.menus = null;
    },
    editCategory() {
      axios({
        url: "/item/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          itemName: this.curItem.itemName,
          itemType: this.curItem.itemType,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>